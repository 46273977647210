<template>
  <div class="wrapper">
    <div class="item">
      <div class="tit">新客户</div>
      <div class="content">
        <span class="num">{{ dataList.client_count }}</span>
        <span class="trend up">
          {{
            ((dataList.client_count / dataList.client_total) * 100).toFixed(2)
          }}%
          <i class="el-icon-caret-top" />
        </span>
      </div>
      <div class="foot">
        客户总数
        <span>{{ dataList.client_total }}</span>
      </div>
    </div>

    <div class="item">
      <div class="tit">新转发</div>
      <div class="content">
        <span class="num">{{ dataList.forward_count }}</span>
        <span class="trend up">
          {{
            ((dataList.forward_count / dataList.forward_total) * 100).toFixed(
              2
            )
          }}%
          <i class="el-icon-caret-bottom" />
        </span>
      </div>
      <div class="foot">
        转发总数
        <span>{{ dataList.forward_total }}</span>
      </div>
    </div>

    <div class="item">
      <div class="tit">商品数</div>
      <div class="content">
        <span class="num">{{ dataList.goods_total }}</span>
      </div>
    </div>

    <div class="item">
      <div class="tit">商品成交量</div>
      <div class="content">
        <span class="num">{{ dataList.order_count }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
  .item {
    background-color: rgba(255, 255, 255, 1);
    padding: 10px 20px;
    margin: 10px;
    flex: calc(50% - 40px);
    border-radius: 5px;

    .tit {
      font-size: 12px;
    }
    .content {
      height: 50px;
      line-height: 50px;
      .num {
        font-size: 30px;
        font-weight: 600;
      }
      .trend {
        font-size: 12px;
        padding: 0 10px;
        &.up {
          color: #f5222d;
        }
        &.down {
          color: #52c41a;
        }
      }
    }
    .foot {
      font-size: 12px;
      opacity: 0.65;
    }
  }
}
</style>


