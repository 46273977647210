<template>
  <div class="wrapper">
    <div class="item-box">
      <div class="title">员工推广榜</div>
      <div class="items">
        <div
          v-for="(item, index) in dataList.relation"
          :key="index"
          class="item"
        >
          <span class="num">{{ index + 1 }}</span>
          <span class="name">{{ item.realname }}</span>
          <span class="prople">{{ item.count }}人</span>
        </div>
      </div>
    </div>

    <div class="item-box">
      <div class="title">动态转发榜</div>
      <div class="items">
        <div
          v-for="(item, index) in dataList.forward"
          :key="index"
          class="item"
        >
          <span class="num">{{ index + 1 }}</span>
          <span class="name">{{ item.title }}</span>
          <span class="prople">{{ item.count }}次</span>
        </div>
      </div>
    </div>

    <div class="item-box">
      <div class="title">商品销售榜</div>
      <div class="items">
        <div v-for="(item, index) in dataList.sale" :key="index" class="item">
          <span class="num">{{ index + 1 }}</span>
          <span class="name">{{ item.name }}</span>
          <span class="prople">{{ item.count }}笔</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box{
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  .title{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
.items {
  .item {
    display: flex;
    margin: 0 -20px;
    padding: 5px 20px;
    align-items: center;
    &:nth-child(-n + 3) {
      .num {
        background-color: #314659;
        color: #fff;
      }
    }
    .num {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      margin-right: 20px;
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 50%;
    }
    .name {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 20px;
      font-size: 14px;
    }
    .prople {
      font-size: 12px;
      opacity: 0.65;
    }
  }
}
</style>