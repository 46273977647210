<template>
  <div class="wrapper">
    <van-image
      round
      width="40px"
      height="40px"
      src="/img/icons/android-chrome-maskable-512x512.png"
    />
    <div class="main">
      <!-- <SearchDate /> -->
    </div>
  </div>
</template>
<script>
// import SearchDate from './searchDate'
export default {
  components: {
    // SearchDate
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding: 10px 0 10px 0;
  align-items: center;
  .main {
    flex: 1;
    text-align: right;
  }
}
</style>