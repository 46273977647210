<template>
  <div class="wrapper">
    <VeLine :data="dataList" />
  </div>
</template>
<script>
import VeLine from 'v-charts/lib/line.common'
export default {
  components: {
    VeLine
  },
  props: {
    dataList: {
      type: Object,
      default: function () {
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px 0;
  background: #fff;
  border-radius: 5px;
  height: 365px;
  margin-bottom: 20px;
}
</style>