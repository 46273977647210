<template>
  <div class="app-container">
    <Header />
    <SearchDate :query="query" />
    <Count :dataList="dataList.count" />
    <Chart :dataList="dataList.chart" />
    <Rank :dataList="dataList" />
    <Tabbar :json="{ active: 0 }" />
  </div>
</template>
<script>
import SearchDate from '@/components/common/searchDate'
import Header from '@/components/common/header.vue'
import Count from './components/count'
import Chart from './components/chart'
import Rank from './components/rank'
import Tabbar from '@/components/common/tabbar.vue'
export default {
  components: {
    SearchDate,
    Header,
    Count,
    Chart,
    Rank,
    Tabbar
  },
  data () {
    return {
      query: {
        chart: {},
        count: {}
      },
      dataList: {},
      container: null
    }
  },
  mounted () {
    // this.setQuery()
  },
  methods: {
    setQuery () {
      this.query.begin_date = this.$moment().subtract(1, 'months').format('YYYY-MM-DD')
      this.query.end_date = this.$moment().format('YYYY-MM-DD')

      this.getDataList()
    },
    getDataList () {
      this.$axios.post('/statistic_index/data', this.query).then(res => {
        if (res.code === 200) {
          this.dataList = res.data
        }
      })
    }
  }
}
</script>